import React, {
  createContext,
  useReducer,
  ReactNode,
  Dispatch,
  useEffect,
} from "react";

interface FilterState {
  page: number;
  pageSize: number;
  total: number;
  totalPages: number;
  [key: string]: string | number;
}

type FilterAction =
  | {
      type: "SET_FILTER";
      payload: { key: string; value: string | number };
    }
  | { type: "SET_PAGE"; payload: number }
  | { type: "SET_PAGE_SIZE"; payload: number }
  | { type: "SET_TOTAL"; payload: number }
  | { type: "SET_TOTAL_PAGES"; payload: number }
  | { type: "RESET_FILTERS" };

const initialState: FilterState = {
  page: 1,
  pageSize: 20,
  total: 0,
  totalPages: 0,
};

const FilterContext = createContext<{
  state: FilterState;
  dispatch: Dispatch<FilterAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

const filterReducer = (
  state: FilterState,
  action: FilterAction
): FilterState => {
  switch (action.type) {
    case "SET_FILTER":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case "SET_PAGE":
      return {
        ...state,
        page: action.payload,
      };
    case "SET_PAGE_SIZE":
      return {
        ...state,
        pageSize: action.payload,
      };
    case "SET_TOTAL":
      return {
        ...state,
        total: action.payload,
      };
    case "SET_TOTAL_PAGES":
      return {
        ...state,
        totalPages: action.payload,
      };
    case "RESET_FILTERS":
      return {
        ...state,
        name: "",
        brand: "",
        gender: "",
        collection: "",
        category: "",
        page: 1,
        pageSize: 20,
      };
    default:
      return state;
  }
};

export const FilterProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(filterReducer, initialState);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const filtersFromUrl: Partial<FilterState> = {};
    searchParams.forEach((value, key) => {
      filtersFromUrl[key] = isNaN(Number(value)) ? value : Number(value);
    });
    Object.keys(filtersFromUrl).forEach((key) =>
      dispatch({
        type: "SET_FILTER",
        payload: { key, value: filtersFromUrl[key]! },
      })
    );
  }, []);

  return (
    <FilterContext.Provider value={{ state, dispatch }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterContext = () => React.useContext(FilterContext);
